class BasicInformation {

    constructor() {
        this.init();
    }

    init(){
        this.about = 'Text about me';

        this.email = 'yarik@lukyanchuk.com';
        this.emailPersonal = 'yarikthe@gmail.com';

        this.phoneKyivstar = '+38 (098) 075 13 57';
        this.phoneLifecell = '+38 (093) 501 90 04';

        this.socialYouTube = 'https://youtube.com/@YarikLukyanchuk';
        this.socialInstagram = 'https://instagram.com/yariklukyanchuk/';
        this.socialFacebook = 'https://facebook.com/yarik.lukyanchuk/';
        this.socialLinkedIn = 'https://linkedin.com/in/yaroslav-lukyanchuk-a028481ab/';
        this.socialTelegram= 'https://t.me/YaroslavLukyanchuk';
        this.socialTwitter= 'https://twitter.com/yariklukyanchuk';
        this.socialGitHub= 'https://github.com/yarikthe';

        this.socialWhatsapp= 'https://api.whatsapp.com/send?phone=0980751357';
        this.socialViber= 'viber://contact?number=%2B0980751357';
        this.socialSignal= 'https://signal.me/#p/+380980751357';

        this.address = 'м.Житомир';

        this.clientURL = 'https://office.lukyanchuk.com';
    }
}

export default new BasicInformation();