

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import axios from "axios";
import {BACK_END_URL} from "../service/api/config";
import Fancybox from "../service/image/fancybox";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";
import * as AiIcons from "react-icons/ai";
import Helmet from "react-helmet";

function PageSingle(props) {

    const { t, i18n } = useTranslation()

    let {slug} = useParams();

    const [project, setProject] = useState({
        "id": 4,
            "name": {
            "en": "English",
            "ua": "Ukraine"
        },
        "description": {
            "en": "English",
                "ua": "Ukraine",
        },
        "image": "http://127.0.0.1:8000/storage/files/portfolio/image.png",
            "service": {
            "id": 4,
                "name": {
                "en": "English",
                    "ua": "Ukraine"
            },
            "note": {
                "en": "English",
                "ua": "Ukraine"},
            "price": {
                "USD": 200
            },
            "image": null,
                "slug": "name",
                "url": "/services/name"
        },
        "content": "",
            "links": {
            "production_link": "https://example.com",
                "repository_url": "https://example.com"
        },
        "files": [
            {
                "url": "http://127.0.0.1:8000/storage/files/file.png",
                "name": "file-1.png",
                "name_file_url": "file-1.png"
            },
        ],
            "slug": "title-example-slug"
    });
    const [load, setLoader] = useState(false);

    // t#tag
    // s#service

    useEffect(()=>{

        setLoader(true);
        axios.get(BACK_END_URL + 'project/' + slug).then(function (response){

            const getProject = response.data;
            setProject(getProject.data);
            console.log(getProject.data);

        }).catch(error => {
            console.log(error);
        })
        setLoader(false);
    }, []);

    const gaEventTracker = useAnalyticsEventTracker('Project: ' + slug);

    if(load){return <div>Loading...</div>}

    return (<div>
        <Helmet>
            <title>{i18n.language === 'en' ? project.name.en :  project.name.ua}</title>
            {/*<meta name="description" content={postSingle.body.en.length > 100 ? postSingle.body.en.substr(0, 100) + '...' : postSingle.body.en} />*/}
            <meta name="keywords" content={Array.isArray(project.tags) ? project.tags.map((tag, i) => tag.label) : 'Software Developer, Yaroslav Lukyanchuk, Laravel, Flutter, React JS, Web Development, Mobile Development, iOS Development, Android Development'} />
            <meta name="author" content={'Yaroslav Lukyanchuk Ярослав Лук\'янчук'} />
            <meta name="description" content={i18n.language === 'en' ? project.description.en :  project.description.ua} />
                      {
                Array.isArray(project.tags) ? project.tags.map((tag, i) => <meta key={i} property="article:tag" content={tag.label} />) : ''
            }

            <meta name="author" content="Yaroslav Lukyanchuk" />
            <meta name="copyright" content="Yaroslav Lukyanchuk" />
            <meta name="application-name" content="Yaroslav Lukyanchuk" />

            {/*TODO: ended meta tag content*/}

            {/*//  For Facebook */}
            <meta property="og:title" content={i18n.language === 'en' ? project.name.en :  project.name.ua} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={project.image.url != null ? project.image.url : 'https://images.unsplash.com/photo-1668400121008-6134fd5b104d'} />
            <meta property="og:url" content="" />
            <meta property="og:description" content="" />

            {/*// <!-- For Twitter -->*/}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={i18n.language === 'en' ? project.name.en :  project.name.ua} />
            <meta name="twitter:description" content="" />
            <meta name="twitter:image" content={project.image.url != null ? project.image.url : 'https://images.unsplash.com/photo-1668400121008-6134fd5b104d'} />

        </Helmet>


        <div className="row align-items-center">
            <div className="col-md-6 mt-5">
                <img src={project.image.url != null ? project.image.url : 'https://images.unsplash.com/photo-1668400121008-6134fd5b104d'} alt="project-image" className={'img-fluid'}/>
            </div>
            <div className="col-md-6 text-start p-md-5 p-4 pt-1">
                <h5 className={'title-section mt-5 text-dark pt-5'}>
                    {project.name[i18n.language]}
                </h5>
                <p>
                    {project.description[i18n.language]}
                </p>
                <span className={'text-dark display-6 m-0'}>#{project.service.name[i18n.language]}</span>
            </div>
        </div>

        <div className="project-information bg-custom">
            <div className="container d-flex flex-wrap p-2 text-white">
                <div className="col-md-6 text-start p-4">
                    <h2>{t('tasks')} & {t('description')}</h2>
                    <br/>
                    <p dangerouslySetInnerHTML={{__html: project.content[i18n.language] }} />
                    {/*<code>*/}
                    {/*    content in new version database show correctly*/}
                    {/*</code>*/}
                </div>
                <div className="col-md-3 text-start p-4 m-0">
                    <h2>{t('view')}</h2>
                    <br/>
                    <p>
                        <a href={project.links.production_link} className={'lead text-secondary'}>{project.links.production_link}</a>
                    </p>
                </div>
                {/*<div className="col-md-6 text-start p-4">*/}
                {/*    <h2>Feedback</h2>*/}
                {/*    <br/>*/}
                {/*    <a href="#feedback">*/}
                {/*        View*/}
                {/*    </a>*/}
                {/*</div>*/}
                <div className="col-md-3 text-start p-4 m-0">
                    <h2>{t('share')}</h2>
                    <br/>
                    <p>
                        <a onClick={()=>gaEventTracker('share-facebook')} href={'https://www.facebook.com/sharer/sharer.php?u=https://lukyanchuk.com/portfolio/' + project.slug} className={'p-2 lead text-secondary'} target="_blank">Facebook</a>
                        <a onClick={()=>gaEventTracker('share-twitter')} href={'https://twitter.com/intent/tweet?url=https://lukyanchuk.com/portfolio/'+ project.slug} className={'p-2 lead text-secondary'} target="_blank">Twitter</a>
                        <a onClick={()=>gaEventTracker('share-telegram')} href={'https://telegram.me/share/url?url=https://lukyanchuk.com/portfolio/'+ project.slug + "&text=" + project.name[i18n.language]} className={'p-2 lead text-secondary'} target="_blank">Telegram</a>
                    </p>
                </div>
            </div>
        </div>

        <div className="project-information">
            <div className="container d-flex flex-wrap p-2 text-white">
                {
                    project.files === null || project.files === [] ? <div>No Files</div> :
                    project.files.map((file, i) =>
                        <div className="col-md-3 text-center p-3">
                        {
                            ['png','jpg', 'jpeg'].includes(file.name_file?.split('.')[1])
                            ? <Fancybox options={{ infinite: false }}>
                                    <img src={file.url} alt={file.name_file} className={'img-fluid shadow'}
                                         data-fancybox="gallery" data-src={file.url}
                                    />
                                </Fancybox>
                                :
                                ['mov','avi', 'mp4'].includes(file.name_file?.split('.')[1]) ?
                                    <div>
                                        <video controls className={'img-fluid rounded shadow'}>
                                            <source src={file.url} type="video/mp4" />
                                                    <p>Your browser does not support the video tag.</p>
                                        </video>
                                        <a download href={file.url} target={'_blank'}> <AiIcons.AiOutlineCloudDownload className={'main-color'} /> {file.name_file}</a>
                                    </div>:
                                <div className={'text-dark text-start'}><h4>Apk</h4> <br/> <a download href={file.url} target={'_blank'} className={'border p-4 bg-dark text-white shadow'}> <AiIcons.AiOutlineCloudDownload className={'main-color'} /> {file.name_file}</a> </div>
                        }
                    </div>)
                }
            </div>

            <div className="container d-flex flex-wrap p-2 text-dark mt-5 pb-5 pt-5 w-100 text-start">
                {/*<div className="col-md-1"> */}
                {/*    #{t('tags')}: */}
                {/*</div>*/}
                {
                    Array.isArray(project.tags) ? project.tags.map((tag, i) =>
                   <div key={i} className="col-md-1 text-secondary"> <a onClick={()=>gaEventTracker('tag_'+tag.label)} href={'/portfolio/tag/' + tag.label} className={'text-secondary'}>#{tag.label}</a>{ project.tags.length - 1 === i ? '' : ','}</div>) : <div>No Recoeds</div>
                }
            </div>

            {/*<br/>*/}

            {/*<div id={'feedback'} className={'container p-5 text-start'}>*/}
            {/*    <h2>Feedbacks</h2>*/}
            {/*    <small className="text-secondary">*/}
            {/*        <i>*/}
            {/*            For leave feedback you need will be client with done project*/}
            {/*        </i>*/}
            {/*    </small>*/}


            {/*    <div className="comment mt-5">*/}

            {/*        <div className="d-flex justify-content-between align-items-center">*/}
            {/*            <span className={'text-start'}>*/}
            {/*                Username*/}
            {/*            </span>*/}
            {/*            <small className="text-secondary text-end">*/}
            {/*                12.12.12*/}
            {/*            </small>*/}
            {/*        </div>*/}

            {/*        <p className="lead text-start">*/}
            {/*            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur at blanditiis commodi deleniti dolor earum ex ipsam maxime modi nemo obcaecati odio quaerat, saepe sapiente sed sunt totam vel, voluptates!*/}
            {/*        </p>*/}

            {/*    </div>*/}

            {/*</div>*/}

            <div className="bg-custom">

                <div className="container p-2 text-start text-white">
                    <h2 className={''}>Similar project</h2>

                    <p>
                        -
                    </p>

                    {/*<code>*/}
                    {/*    in admin set optional choose similar project, in BD set field similar_ids*/}
                    {/*</code>*/}

                    {/*<div className="d-flex">*/}

                    {/*    <div className="col-md-4 p-2">1</div>*/}
                    {/*    <div className="col-md-4 p-2">2</div>*/}
                    {/*    <div className="col-md-4 p-2">3</div>*/}

                    {/*</div>*/}

                </div>

            </div>

            <div className="pt-5 pb-5">

                <a href="/portfolio" className="btn ">

                    Back to Portfolio
                </a>

                <a onClick={()=>gaEventTracker('redirect-single-to-offer')} href="/cooperation" className="btn-custom-black">
                    <img src="/assets/icon/arrow.png" alt="icon-arrow" className={'arrow'}/>
                    {t('form.title2')}
                </a>

            </div>
        </div>

    </div>);
}

export default PageSingle;