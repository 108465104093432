import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import PartnerCard from "../card/partner_card";
import useAnalyticsEventTracker from "../../service/g-analytics/useAnalyticsEventTracker";

function FirstSection(props) {

    const {t, i18n} = useTranslation()
    const gaEventTracker = useAnalyticsEventTracker('Information');

    return (
        <div className="hero-image">

            <div className="hero-text vh-1001 flex-column d-flex justify-content-between align-content-between">

                <div className="row m-auto w-100 d-flex align-items-center justify-content-center">

                    <div className="col-md-6 col-12">
                        {/*<div className="button-offer">*/}
                        {/*    <a href="" className={'btn-custom-white text-transform-uppercase'}>Let's Talk</a>*/}
                        {/*</div>*/}
                    </div>

                    <div className="col-md-6 col-12 text-start">
                        <div className="text">
                            <h1 className={'title'}> {t('hello')} </h1>
                            <h3 className={'p-2'}>{t('position')}</h3>
                            {/*<br/><br/>*/}
                            {/*<a onClick={() => gaEventTracker('offer')} href="/cooperation"*/}
                            {/*   className={'btn-custom-white text-transform-uppercase'}>{t('lets.talk')}</a>*/}
                        </div>
                    </div>
                </div>
                <div className="pt-5 mt-5">

                </div>
            </div>
            <a href="#services" className={''}>

                {/*<svg className="arrows">*/}
                {/*    <path className="a1" d="M0 0 L30 32 L60 0"></path>*/}
                {/*    <path className="a2" d="M0 20 L30 52 L60 20"></path>*/}
                {/*    <path className="a3" d="M0 40 L30 72 L60 40"></path>*/}
                {/*</svg>*/}

                <div className="field text-white pt-5">
                    <div className="mouse"></div>
                </div>
            </a>
        </div>
    );
}

export default FirstSection;