

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function ThankYouPage(props) {

    const { t, i18n } = useTranslation()

    const gaEventTracker = useAnalyticsEventTracker('Offer was Send');

    return (<div className={'v-100'}>
        <Section title={'Thank you for your Offer'} name={'thank-you'}/>
        <div className="col-md-3 shadow pb-2">
            <img src="https://images.unsplash.com/photo-1526614180703-827d23e7c8f2" className={'img-fluid mb-3'} alt="thank-you" height={'500'}/>
            <h1>{t('page.thank-you.title')}</h1>
            <p>
                {t('page.thank-you.subtitle')}
            </p>
        </div>
        <br/><br/>
        <a href="/" className={'btn-custom-black shadow-lg'}>{t('page.thank-you.btn.back')}</a>
        <br/><br/>
        <a href="https://office.lukyanchuk.com/" className={'text-secondary'} onClick={()=>gaEventTracker('redirect-login')}>{t('page.thank-you.btn.sig-in')}</a>
        <br/><br/>
    </div>);
}

export default ThankYouPage;
