
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import PartnerCard from "../card/partner_card";

function ButtonSection(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'text-center pb-5'}>

        <div className="p-2 mt-2 pt-2 mb-5">
            <h2 className={'title-section-2 mt-5 mb-5 text-white'}>
                {t('text.hi')}
            </h2>

           <div className="d-flex justify-content-between align-items-center border m-md-0 mb-3">
               <h5 id={'title-hi'} className={'title-section text-white'}>
                   {t('say.hi')}
               </h5>
               <img src="/assets/icon/arrow.png" alt="icon-arrow" className={'arrow w-25'}/>
           </div>


            <a href="/offer" id={'btn-start-conversation'} className={'btn-custom-white text-transform-uppercase'}>{t('start.conversation')}</a>

        </div>
    </div>);
}

export default ButtonSection;