import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import CooperationForm from "../components/cooperation_form";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function OfferPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5 pb-5">
            <h1 className={'title-section font-monospace'}>{t('nav.cooperation')}</h1>

            {/*<p className="lead">*/}
            {/*    {t('form.title')}*/}
            {/*</p>*/}
        </div>

        <div className="container-fluid bg-custom1 text-white">
            {/*t('form.slogan')*/}

            <div className="container bg-custom pb-5 pt-3 shadow-lg">
                <FormOffer />
            </div>


            {/*<Section title={''} name={'offer'} child={<div className={'bg-custom'}>*/}
            {/*    <FormOffer />*/}
            {/*</div>} />*/}
        </div>
        <div className="bg-white text-white">
            line block
        </div>
    </div>);
}

export default OfferPage;
