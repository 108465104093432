

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";

function PageApp(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'h-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <div className="row align-items-start">
                <div className="col-md-6 sticky-top">
                    <div className=" shadow-lg-rounded-5 p-5 mt-5">
                        <h1 className={'title-section'}>Mobile App</h1>
                        <br/>
                        <h2>Coming soon...</h2>
                        {/*<a href="#download" className={'btn-custom-black border-0 p-3'}>*/}
                        {/*    {t('download')}*/}
                        {/*</a>*/}
                        {/*<a href="#future" className={'btn-custom-black1 text-dark border-0 p-3'}>*/}
                        {/*    {t('Learn more')}*/}
                        {/*</a>*/}
                    </div>
                </div>
                <div className="col-md-6">
                    <img src="https://i.pinimg.com/originals/f3/61/53/f3615368cf0f682093efd99ca9523393.png" alt="iphone" className={'img-fluid'}/>
                    {/*<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/IPhone_5.png/800px-IPhone_5.png" alt="iphone" className={'img-fluid'}/>*/}
                </div>
            </div>
            <div className="vh-100 d-flex justify-content-center align-items-center">

                <div>
                    <div className="p-5">
                        <h2>What to be in app?</h2>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="shadow rounded-5 p-5">
                                    <h5><b>
                                        Track project
                                    </b></h5>
                                    <p>
                                        Easy way to track project progress
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="shadow rounded-5 p-5">
                                    <h5><b>
                                        Gift's
                                    </b></h5>
                                    <p>
                                        Other gifts and bonuses from partners
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="shadow rounded-5 p-5">
                                    <h5><b>
                                        Support
                                    </b></h5>
                                    <p>
                                        Free support 24/7, consultation and more.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="shadow rounded-5 p-5">
                                    <h5><b>
                                        Cross-platform
                                    </b></h5>
                                    <p>
                                        Available to download on Android and iOS
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6 text-center m-auto shadow1 rounded-5 p-5">

                        <p className={'mt-5'}>
                            {/*Be first who download app!*/}
                            Be first who know about avaliable download app!
                        </p>
                        <h3><b className={'text-secondary'}>
                            Subscribe on <a href="https://www.instagram.com/yariklukyanchuk/" className={'text-dark'}>@yariklukynchuk</a>
                        </b></h3>
                        {/*<br/>*/}
                        {/*<div className="form-subscribe">*/}
                        {/*    <input type="email" className="email p-3 border-0 border-bottom" placeholder={'email@mail.com'}/>*/}
                        {/*    <button className="btn-custom-black border-0 p-3">Join</button>*/}
                        {/*</div>*/}
                    </div>

                </div>
            </div>


        </div>
    </div>);
}

export default PageApp;
