

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import {Route, Routes} from "react-router-dom";
import OfferPage from "./offer";
import ServicesPage from "./services";
import ProjectsPage from "./projects";
import PageSingle from "./single";
import ContactsPage from "./contacts";
import ThankYouPage from "./thank-you";
import PageInformation from "./information";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function PageMapSite(props) {

    const { t, i18n } = useTranslation()

    const data = JSON.parse(localStorage.getItem("projects"));

    const gaEventTracker = useAnalyticsEventTracker('Map site');

    return (
        <div className={'vh-100 pt-5'}>

            <div className="container text-dark text-start pt-5 mt-5">

                <h1 className={'title-section'}>{t('nav.site-map')}</h1>

                <div>
                    <Section title={''} name={'site-map'} child={<div>

                        {/*<a href="/information#support" className={'text-secondary'}><span>{t('nav.donate')}</span></a> <br/>*/}
                        {/*<a href="/information#vacancy" className={'text-secondary'}><span>{t('nav.vacancy')}</span></a> <br/>*/}
                        <a href="/information#privacy-policy" className={'text-secondary'}><span>{t('nav.privacy-policy')}</span></a> <br/>
                        <a href="/information#term-of-use" className={'text-secondary'}><span>{t('nav.term-of-use')}</span></a> <br/>
                        <a href="/site-map" className={'text-secondary'}><span>{t('nav.site-map')}</span></a> <br/>
                        <a href="/cooperation" className={'text-secondary'}><span>{t('nav.cooperation')}</span></a> <br/>
                        <a href="/portfolio" className={'text-secondary'}> <span>{t('nav.portfolio')}</span></a> <br/>
                        <a href="/about-me" className={'text-secondary'}> <span>{t('nav.about me')}</span></a> <br/>
                        <a href="/contacts" className={'text-secondary'}> <span>{t('nav.contacts')}</span></a> <br/>
                        {/*<a href="/projects" className={'text-secondary'}> <span>{t('nav.projects')}</span></a> <br/>*/}
                        {
                            (data !== null || data !== []) ??
                            data.map(e => <div>
                                <a href={'project/' + e.slug} className={'m-5'}>
                                    {
                                        e.name[i18n.language]
                                    }
                                </a>
                            </div>)
                        }
                        {/*<a href="/services" className={'text-secondary'}> <span>{t('nav.services')}</span></a> <br/>*/}
                        {/*<a href="https://office.lukyanchuk.com" className={'text-secondary'}><span>{t('nav.enter')}</span></a><br/>*/}


                    </div>}/>
                </div>

                <div className="pt-5 pb-5">

                    <a href="/" className="btn-custom-black">{t('nav.home')}</a>

                </div>
            </div>
        </div>);
}

export default PageMapSite;
