import React, {Suspense, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
// import ServiceSection from "../components/section/service_section";
import ProjectsSection from "../components/section/projects_section";
import OfferPage from "./offer";
import FormOffer from "../components/form";
import ReactSwipe from "react-swipe";
import Information from "../components/information";
import BASIC_INFORMATION from '../service/information/basic';
import PartnersSection from "../components/section/partners_section";
import ButtonSection from "../components/section/button_section";
import {CookieConsent} from "react-cookie-consent";
import axios from "axios";
import {BACK_END_URL_API} from "../service/api/config";
import AppSection from "../components/section/app_section";
import {MotionAnimate} from "react-motion-animate";
import ClientsSection from "../components/section/clients_section";
import Helmet from "react-helmet";
import BooksSection from "../components/section/books_section";

const ServiceSection = React.lazy(() => import("../components/section/service_section"));

function HomePage(props) {

    const { t, i18n } = useTranslation()

    const recordVisit = () => {
        axios.post(BACK_END_URL_API + 'detected', [], {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then((response) => {
            console.log(response.data)
        });
    }

    useEffect(() => {
        recordVisit()
    }, [])

    //TODO: more animation on scroll https://suhmantha1.github.io/react-motion-animate/

    return (<div>

        <Helmet>
            <title>Yaroslav Lukyanchuk</title>
            <meta name="description" content="Yaroslav Lukyanchuk" />
        </Helmet>

        <Section title={''} name={'slogan'} child={

            <div className={'row align-items-center pb-3'}>
                <div className={'col-md-9 col-12 pt-4'}>
                    <h2>{t('slogan')}</h2>
                    <small className="text-secondary lead1">
                        {t('slogan.ps')}
                    </small>
                </div>

                <div className={'col-md-3 col-12 pt-4'}>
                    <a href="/cooperation"
                       className={'btn-custom-black text-transform-uppercase'}>{t('try.do')}</a>
                </div>
            </div>



        } link={''} linkName={''} bg={'bg-custom1'}/>

        <Section title={''} name={'services'} child={
            <Suspense fallback={<div>Loading...</div>}>
                <ServiceSection />
            </Suspense>
        } link={''} linkName={''} bg={'bg-custom'}/>
        <Section name={'clients'} child={<ClientsSection />} />
        <Section title={''} name={'stack-technologies d-none-d-md-block'} child={
            <div id={'stack'} className={'bg-white1 d-flex flex-wrap justify-content-between align-items-center p-1 pb-4'}>
                <div className={'col-md-2 col-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" alt="Laravel" className={'img-fluid p-md-5 p-1'}/>
                    {/*<br/>*/}
                    {/*Laravel*/}
                </div>

                <div className={'col-md-2 col-2 text-center'}>
                    <img src="https://plugins.jetbrains.com/files/9212/376589/icon/pluginIcon.png" alt="Flutter" className={'img-fluid p-md-5 p-1'}/>
                    {/*<br/>*/}
                    {/*Flutter*/}
                </div>

                <div className={'col-md-2 col-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" alt="react.js" className={'img-fluid p-md-5 p-1'}/>
                    {/*<br/>*/}
                    {/*React.JS*/}
                </div>

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png" alt="next.js" className={'img-fluid p-2'}/>*/}
                {/*    /!*<br/>*!/*/}
                {/*    /!*React.JS*!/*/}
                {/*</div>*/}

                <div className={'col-md-2 col-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png" alt="Bootstrap" className={'img-fluid p-md-5 p-1'}/>
                    {/*<br/>*/}
                    {/*Bootstrap*/}
                </div>

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/23-swift-512.png" alt="Bootstrap" className={'img-fluid p-5'}/>*/}
                {/*    /!*<br/>*!/*/}
                {/*    /!*Bootstrap*!/*/}
                {/*</div>*/}

                <div className={'col-md-2 col-2 text-center'}>
                    <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/MySQL-Logo.wine.png" alt="MySQL" className={'img-fluid p-md-5 p-1'}/>
                    {/*<br/>*/}
                    {/*MySQL*/}
                </div>

                <div className={'col-md-2 col-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png" alt="Git" className={'img-fluid p-md-5 p-1'}/>
                </div>

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://www.mamp.info/images/icons/mamp-pro.png" alt="Git" className={'img-fluid p-5'}/>*/}
                {/*</div>*/}

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1200px-Visual_Studio_Code_1.35_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>*/}
                {/*</div>*/}
            </div>
        } link={''} linkName={''} bg={'bg-custom'}/>
        <Section title={''} name={'portfolio'} child={<ProjectsSection limit={6} />} link={''} linkName={''}/>
        <Section title={''} name={'button-section'} child={<ButtonSection />} link={''} linkName={''} bg={'bg-custom'}/>

        <Section name={'books'} child={<BooksSection />} />

        <Section title={''} name={'partners'} child={<AppSection />} link={''} linkName={''}  bg={'bg-custom d-none d-md-block'}/>

        <Section title={''} name={'partners'} child={<PartnersSection />} link={''} linkName={''}/>



        {/*<Section title={t('nav.services')} name={'services'} child={<ServiceSection />} link={'/cooperation'} linkName={t('nav.cooperation')}/>*/}
        {/*<section>*/}
        {/*    <div className="image-banner shadow">*/}
        {/*        <img src="https://images.unsplash.com/photo-1646081499142-3c2945cafdc1" alt="image-banner" className={''}/>*/}
        {/*    </div>*/}
        {/*</section>*/}
        {/*<Section title={t('nav.cooperation')} name={'cooperation'} child={<FormOffer />} link={'/#cooperation'} linkName={''}/>*/}

        <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="myCookieName"
            expires={150}
            containerClasses="col-lg-12 d-flex align-items-center bg-transparent shadow"
            contentClasses="p-1"
            buttonClasses={"shadow"}
            buttonStyle={{borderRadius: "10px", background: "white", padding: "25px", color: "#1e1e1e"}}
        >
            <div className="p-3 bg-dark border-white rounded-4">
                    { t('cookie') }.
                    <br/>
                    <span>
                    <a href="/information">{ t('cookie.info') }.</a>
                </span>
            </div>
        </CookieConsent>

    </div>);
}

export default HomePage;
