import React from "react";
import {useTranslation} from "react-i18next";

function PortfolioCard(props) {
    const { t, i18n } = useTranslation();

    const lang = i18n.language;

    return (
            <div className="col-md-6 col-12">
                <div className="m-1 portfolio-card-item row d-flex border-dark border-5 shadow">
                    <div className="col-md-5 col-12 p-0 m-auto">
                        <img src={props.data.image.name != '' ? props.data.image.url : 'https://images.unsplash.com/photo-1668400121008-6134fd5b104d'} alt="image" className={'img-fluid- portfolio-img'}/>
                    </div>
                    <div className="col-md-7 col-12 align-items-center d-flex">
                        <div className="portfolio-information p-md-5 p-1 text-start">

                        <span className={'m-0'}>
                            #{lang === 'en' ? props.data.service.name?.en : props.data.service.name?.ua}
                        </span>
                            <h2 className={'pt-3 font-monospace- font-weight-bold'}>
                                {lang === 'en' ? props.data.name?.en : props.data.name?.ua}
                            </h2>
                            <p className={'pt-3 text-justify d-none d-md-block'}>
                                {props.data.description?.lang?.length > 100 ? props.data.description?.lang.substr(0, 100) + '...' : lang === 'en' ? props.data.description?.en : props.data.description?.ua}
                            </p>
                            <a href={"/portfolio/" + props.data.slug} className={'link-know-more'}>
                                { t('nav.know.more')}
                                <img src="/assets/icon/arrow-black.png" alt="icon-arrow" className={'arrow'}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
    );

}

export default PortfolioCard;
