
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";

function ProjectsSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        {/*<hr/>*/}
        {/*<ProjectApi limit={props.limit} />*/}

        <div className="bg-white p-2 mt-5 pt-5">
            <h5 className={'title-section mt-5'}>
                {t('nav.portfolio')}
            </h5>
            <p className={'lead mt-2 mb-5 pb-5'}>
                {t('portfolio.text')}
            </p>

            <ProjectApi limit={4} />

            <div className="d-flex align-items-center justify-content-center p-5">

                <a href="/portfolio" className="btn-custom-black">{t('browse.all')}</a>

            </div>
        </div>
    </div>);
}

export default ProjectsSection;