import React, {useEffect, useState} from "react";
import './../App.css';
import {useTranslation} from "react-i18next";
import Navbar from "./navbar/navbar";
import FirstSection from "./section/first_section";
import { useLocation } from 'react-router-dom';

function Header() {
    const { t, i18n } = useTranslation();

    const location = useLocation();

    return (
       <div className="container-fluid p-0">
           <header className="header fixed-top">
               <Navbar />
           </header>

           {
               location.pathname === '/' && <FirstSection />
           }

       </div>
    );
}

export default Header;
