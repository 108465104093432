import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function Language() {

    const { t, i18n } = useTranslation()

    const [language, setLetnguage] = useState('ua');

    const gaEventTracker = useAnalyticsEventTracker('Language');

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        setLetnguage(event.target.value);
        console.log('language: ' + event.target.value);
        gaEventTracker('language' + event.target.value)
    }

    return (<div>
        <div id="lang-switch">
            <div onChange={changeLanguage}>
                <ul className={'list-unstyled d-flex justify-content-end'}>
                    <li></li>
                    <li className={'p-1'}>
                        <label className="radio-img p-11 flag-language bg-transparent">
                            <input type="radio" value="en" name="language" />
                            <img src="/assets/lang/images/uk.png" alt="English" width="20"/>
                            {/*<span> {language === 'en' ? ' English' : ' Англійська'}</span>*/}
                        </label>
                    </li>
                    <li className={'p-1'}>
                        <label className="radio-img p-11 flag-language bg-transparent">
                            <input type="radio" value="ua" name="language" defaultChecked />
                            <img src="/assets/lang/images/ua.png" alt="Ukraine" width="20"/>
                            {/*<span>{language === 'ua' ? ' Українська' : ' Ukraine'}</span>*/}
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>);
}

export default Language;
