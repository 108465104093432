import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Language from "./language";

import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../service/information/basic';
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function Footer() {
    const { t, i18n } = useTranslation();

    const _versionWebsite = [
        "v1", "v2", "v3", "v4", "v4.2.1"
    ];

    const handleClick = (e) => {
        alert('2');
        window.location.href = 'https://' + _versionWebsite[e.target.value] + '.lukyanchuk.com'
    }

    const gaEventTracker = useAnalyticsEventTracker('Information');

    return (
        <footer id={'contacts'} className={'footer text-start p-md-5 p-2'}>
            <div className="container">
                <div className="footer-header mb-5">
                    <h5 className={'title-section p-2'}>{t('footer')}</h5>
                </div>

                <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">

                    <div className="footer-contacts text-start w-md-50 w-100 pt-1 p-1">

                        <span className={'text-secondary'}>{t('nav.contacts')}</span>
                        <div className="links text-start pt-4 p-3 mb-5 pb-5">

                            {/*<a onClick={()=>gaEventTracker('phone-kyivstar')} href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'text-white'}> <FaIcons.FaMobileAlt />*/}
                            {/*    <span>{BASIC_INFORMATION.phoneKyivstar}</span>*/}
                            {/*</a>*/}
                            <a onClick={()=>gaEventTracker('social-telegram')} href={BASIC_INFORMATION.socialTelegram} className={'text-white'}> <FaIcons.FaTelegram /> <span>Telegram</span></a>
                            <br/> <br/>
                            {/*<span>*/}
                            {/*    <a href={'tel.:' + BASIC_INFORMATION.phoneLifecell} className={'text-white'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneLifecell}</a>*/}
                            {/*</span> <br/> <br/>*/}
                            <a onClick={()=>gaEventTracker('email')} href={
                                'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Footer'
                            } className={'text-white'}><FaIcons.FaEnvelope /> <span> {BASIC_INFORMATION.email}</span></a> <br/>
                        </div>

                        <span className={'text-secondary'}>{t('nav.follow-us')}</span>
                        <div className="links text-start pt-2 d-flex flex-wrap">
                            <div className="p-3 col-md-4 col-6">
                                <a onClick={()=>gaEventTracker('social-instagram')} href={BASIC_INFORMATION.socialInstagram} className={'text-white'}> <FaIcons.FaInstagram /> <span>Instagram</span></a>
                            </div>
                            {/*<div className="p-3 col-md-4  col-6">*/}
                            {/*    <a onClick={()=>gaEventTracker('social-telegram')} href={BASIC_INFORMATION.socialTelegram} className={'text-white'}> <FaIcons.FaTelegram /> <span>Telegram</span></a>*/}
                            {/*</div>*/}
                            <div className="p-3 col-md-4  col-6">
                                <a onClick={()=>gaEventTracker('social-facebook')} href={BASIC_INFORMATION.socialFacebook} className={'text-white'}> <FaIcons.FaFacebook /> <span>Facebook</span></a>
                            </div>
                            {/*<div className="p-3 col-md-4  col-6">*/}
                            {/*    <a onClick={()=>gaEventTracker('social-github')} href={BASIC_INFORMATION.socialGitHub} className={'text-white'}> <FaIcons.FaGithub /> <span>GitHub</span></a>*/}
                            {/*</div>*/}
                            <div className="p-3 col-md-4  col-6">
                                <a onClick={()=>gaEventTracker('social-linkedin')} href={BASIC_INFORMATION.socialLinkedIn} className={'text-white'}> <FaIcons.FaLinkedinIn /> <span>LinkedIn</span></a>
                            </div>
                            {/*<div className="p-3 col-md-4  col-6">*/}
                            {/*    <a onClick={()=>gaEventTracker('social-twitter')} href={BASIC_INFORMATION.socialTwitter} className={'text-white'}> <FaIcons.FaTwitter /> <span>Twitter</span></a>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="footer-qrcode w-md-50 w-100 border- ">

                        <div className="d-none d-md-block">
                            <div className="row d-flex p-1 hidden-block">
                                <div className="qrcode-text col-md-6 text-center d-flex align-items-center">
                                    <div className="content">
                                        <h2>
                                            {t('scan.qrcode')}
                                        </h2>

                                        <br/>

                                        <img src="/assets/icon/arrow.png" alt="icon-arrow" className={'w-25'}/>
                                    </div>
                                </div>

                                <div className="qrcode-image col-md-6 text-end">
                                    <img src="/assets/qrcode/qr-code.png" alt="qr-code" className={'img-fluid shadow w-75'}/>
                                </div>
                            </div>

                            <div className="language bg-white p-2 mt-4 row d-flex align-items-center justify-content-between m-1">
                                <div className="offer col-md-10">
                                    <a href="/offer" className={'text-dark title-offer'}>{t('lets.talk')}</a>
                                </div>
                                <div className="translate col-md-2 text-end pr-3">
                                    <img src="/assets/icon/translate.png" alt="icon-translate" className={'w-50'}/>
                                </div>
                            </div>
                        </div>

                        <hr className={'d-block d-md-none'}/>

                        <div className="d-flex justify-content-between align-items-center footer-links-information">
                            <div className="m-0 d-none d-md-block">
                                <a href="/information#privacy-policy" className={'text-secondary p-1'}>{t('nav.privacy-policy')}</a>|
                                <a href="/information#term-of-use" className={'text-secondary p-1'}>{t('nav.term-of-use')}</a>|
                                <a href="/site-map" className={'text-secondary p-1'}>{t('nav.site-map')}</a>
                            </div>
                            <div className="m-0 d-block d-md-none">
                                <a href="/information#privacy-policy" className={'text-secondary p-1'}>{t('nav.privacy-policy')}</a> <br/>
                                <a href="/information#term-of-use" className={'text-secondary p-1'}>{t('nav.term-of-use')}</a> <br/>
                                <a href="/site-map" className={'text-secondary p-1'}>{t('nav.site-map')}</a>
                            </div>
                            <div className="m-0 pt-3">
                                <Language />
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="footer-copyright-information pt-4 text-md-start_text-center p-md-0 pb-4 d-flex align-items-center justify-content-between">

                    <div className={'col-md-6 text-start'}>
                        <span className={'text-secondary'}>{t('copyright')} © {(new Date().getFullYear())}.  {t('title')}.</span>

                    </div>

                    <div className={'col-md-6 text-end'}>
                        <select name="" id="version-website"
                                style={{
                                    appearance: 'none',
                                    webkitAppearance: 'none',
                                    mozAppearance: 'none',
                                    fontSize: '12px',
                                    padding: '20px'
                                }}
                                onChange={handleClick}
                                className={'bg-transparent text-secondary border border-secondary p-1 rounded-pill'}>
                            {
                                _versionWebsite.map((version, i) => <option
                                    value={i}
                                    selected={i === _versionWebsite.length-1}
                                    className={'m-2'}
                                >
                                    <small className={'text-secondary'}>{version}</small>
                                </option>)
                            }
                        </select>

                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
