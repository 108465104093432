import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {BACK_END_URL} from './config';
import {useTranslation} from "react-i18next";

const ProjectApi = (props) => {

    const { t, i18n } = useTranslation()

    const [project, setProject] = useState([]);
    const [load, setLoad] = useState(false);

    const serviceIDs = props.service ? '&service=' + props.service : '';
    const limit = '?limit=' + props.limit;

    useEffect(()=>{

        setLoad(true);
        axios.get(BACK_END_URL + 'projects' + limit +  '&sort=DESC'+serviceIDs).then(function (response){
            const getProject = response.data;
            setProject(getProject.data);
            console.log(getProject.data);

        }).catch(error => {
            console.log(error);
        })
        setLoad(false);
    }, []);

    return <div>
        <div className="">
            {
                load ? <div className={'text-secondary'}>Loading</div> :
                project == null ? <div className={'text-secondary'}>No Records</div> :
                project.map((item, index) => {
                      return  index % 2 === 0 ?

                            <div className="portfolio-card row d-flex border-dark border-5 shadow mb-5 align-items-center">
                                <div className="col-md-7 col-12 p-0 text-center">
                                    <img
                                        src={item.image.name != '' ? item.image.url : 'https://images.unsplash.com/photo-1667470928088-57bfc3f28cf8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1026&q=80'}
                                        alt="image" className={'img-fluid- home-portfolio-img'}/>
                                </div>
                                <div className="col-md-5 col-12 align-items-center d-flex">
                                    <div className="portfolio-information p-md-5 p-3">

                        <span className={'m-0'}>
                            #{item.service.name[i18n.language === 'en' ? 'en' : 'ua']}
                        </span>
                                        <h2 className={'pt-3 font-monospace- font-weight-bold'}>
                                            {item.name[i18n.language === 'en' ? 'en' : 'ua']}
                                        </h2>
                                        <p className={'pt-3 text-justify d-none d-md-block'}>
                                            {item.description[i18n.language === 'en' ? 'en' : 'ua']}
                                        </p>
                                        <a href={"/portfolio/" + item.slug} className={'link-know-more'}>
                                            {t('nav.know.more')}
                                            <img src="/assets/icon/arrow-black.png" alt="icon-arrow" className={'arrow'}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            :
                          <div className="portfolio-card row shadow mb-5">

                              <div className="col-md-5 col-12 align-items-center d-flex align-items-center">
                                  <div className="portfolio-information p-md-5 p-3">

                        <span className={'m-0'}>
                             #{item.service.name[i18n.language === 'en' ? 'en' : 'ua']}
                        </span>
                                      <h2 className={'pt-3 font-monospace- font-weight-bold'}>
                                          {item.name[i18n.language === 'en' ? 'en' : 'ua']}
                                      </h2>
                                      <p className={'pt-3 text-justify d-none d-md-block'}>
                                          {item.description[i18n.language === 'en' ? 'en' : 'ua']}
                                      </p>
                                      <a href={"/portfolio/" + item.slug} className={'link-know-more'}>
                                          {t('nav.know.more')}
                                          <img src="/assets/icon/arrow-black.png" alt="icon-arrow" className={'arrow'}/>
                                      </a>
                                  </div>
                              </div>

                              <div className="col-md-7 col-12 p-0 text-center">
                                  <img src={item.image.name != '' ? item.image.url : 'https://images.unsplash.com/photo-1667470928088-57bfc3f28cf8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1026&q=80'} alt="image" className={'img-fluid- home-portfolio-img'}/>
                              </div>
                          </div>
                    }
                )
            }
        </div>
    </div>

}

export default ProjectApi