import Footer from "./components/footer";
import Header from "./components/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./pages/home";
import OfferPage from "./pages/offer";
import {Routes, Route, Link, Navigate} from "react-router-dom";
import PageNotFound from "./pages/404";
import ProjectsPage from "./pages/projects";
import BackToTop from "react-back-to-top-button";
import ThankYouPage from "./pages/thank-you";
import React, {useEffect, useState} from "react";
import PageMapSite from "./pages/map-site";
import PageInformation from "./pages/information";
import PageSingle from "./pages/single";
import PageDetailService from "./pages/services_detail";
import 'animate.css';
import PageAboutMe from "./pages/about-me";
import {useTranslation} from "react-i18next";
import ReactGA from 'react-ga';
import PageDetailProject from "./pages/project_detail";
import PageTracking from "./pages/tracking";
import PageProject from "./pages/project";
import PageApp from "./pages/app";
import ServicesPage from "./pages/services";
import ContactsPage from "./pages/contacts";
const TRACKING_ID = "UA-86660902-4"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

    const { t, i18n } = useTranslation()

    // const [services, setServices] = useState('');
    //
    useEffect(()=>{
        // setServices(localStorage.getItem("services"))
        // console.log('services' + services);
        i18n.changeLanguage(i18n.language)
    })

    return (
        <div className="App">
            <Header/>
            <main>
                <Routes>
                    <Route index exact path={'/'} element={<HomePage/>}/>

                    <Route exact path={'/cooperation'} element={<OfferPage/>}/>
                    <Route exact path={'/offer'} element={<OfferPage/>}/>
                    <Route exact path={'/contacts'} element={<ContactsPage/>}/>

                    <Route exact path={'/services/'} element={<ServicesPage />}/>
                    <Route exact path={'/services/:slug'} element={<PageDetailService />}/>

                    <Route exact path={'/work-tracking/'} element={<PageTracking />}/>

                    {/*<Route exact path={'/projects/'} element={<PageProject />}/>*/}
                    {/*<Route exact path={'/project/:slug'} element={<PageDetailProject />}/>*/}

                    <Route exact path={'/portfolio/tag/:name'} element={<ProjectsPage type={'tag'}/>}/>
                    <Route exact path={'/portfolio/service/:name'} element={<ProjectsPage type={'service'}/>}/>
                    <Route exact path={'/portfolio'} element={<ProjectsPage type={'default'} />}/>
                    <Route exact path={'/portfolio/:slug'} element={<PageSingle/>}/>

                    <Route exact path={'/thank-you'} element={<ThankYouPage/>}/>
                    <Route exact path={'/site-map'} element={<PageMapSite/>}/>
                    <Route exact path={'/information'} element={<PageInformation/>}/>


                    <Route exact path={'/mobile-application'} element={<PageApp/>}/>

                    <Route exact path={'/about-me'} element={<PageAboutMe />}/>

                    <Route path="*" element={<PageNotFound/>}/>
                </Routes>
            </main>
            <BackToTop
                showOnScrollUp
                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >
                <div className="button-top d-flex align-items-center">
                    <img src="/assets/icon/arrow-top.png" alt="icon-arrow-top" className={'arrow'}/>
                    {/*<span className={''}>Top</span>*/}
                </div>
            </BackToTop>
            <Footer/>
        </div>
    );
}

export default App;