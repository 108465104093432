import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../service/information/basic';
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";
import './contacts.css';

function ContactsPage(props) {

    const { t, i18n } = useTranslation()

    const gaEventTracker = useAnalyticsEventTracker('Contact us');

    return (<div className={'pt-5'}>
       <div className={'contacts-page container text-start mt-5 pt-5 bg-white mb-5'}>
           <div className="block p-5 d-none d-md-block">
               {/*https://images.unsplash.com/photo-1530541263627-4314dd7a44c2*/}
               {/*<img src="https://images.unsplash.com/photo-1557180295-76eee20ae8aa" className={'img-fluid'} alt="contact-page-image"/>*/}

               <div className="map-image">
                   <div className="points mapsmall-circle1 anim animate">


                   </div>

                   <div className="points mapsmall-circle2">

                   </div>
                   <div className="points mapsmall-circle3"></div>
                   <div className="points mapsmall-circle4"></div>
                   <div className="points mapsmall-circle5"></div>
                   <div className="points mapsmall-circle6"></div>
                   <div className="points mapsmall-circle7"></div>
                   <div className="points mapsmall-circle8"></div>
                   <div className="points mapsmall-circle9"></div>
                   <div className="points mapsmall-circle10"></div>
                   <div className="points mapsmall-circle11"></div>
                   <div className="points mapsmall-circle12"></div>
                   <div className="points mapsmall-circle13"></div>

                   <div className="points mapsmall-circle14"></div>
                   <div className="points mapsmall-circle15"></div>
                   <div className="points mapsmall-circle16"></div>
                   <div className="points mapsmall-circle17"></div>


                   <svg id="my-svg">


                       <path id="p_one" d="M147 149 C147, 149  137,92 197 100  " stroke-width="1" fill="none"></path>
                       <path id="p-two" d="M147 149 C147, 149 234,110 322 164  " stroke-width="1" fill="none"></path>
                       <path id="p-three" d="M131,185 C131,185 310,36 344,83 " stroke-width="1" fill="none"></path>
                       <path id="p-four" d="M194 313 C194 313 300,50 332 119 " stroke-width="1" fill="none"></path>
                       <path id="p-five" d="M324 167 C324 167 393,122 420 184  " stroke-width="1" fill="none"></path>
                       <path id="p-six" d="M153 100 C153 100 159,47 234 72   " stroke-width="1" fill="none"></path>
                       <path id="p-seven" d="M187 122 C187 122 309,0 456 124      " stroke-width="1" fill="none"></path>
                       <path id="p-eight" d="M244 257 C244 257 310,15 590 91      " stroke-width="1" fill="none"></path>
                       <path id="p-nine" d="M242 261 C242 261 383,161 568 274  " stroke-width="1" fill="none"></path>
                       <path id="p-ten" d="M133 90 C133 90 310,15 486 150  " stroke-width="1" fill="none"></path>
                       <path id="p-eleven" d="M131 184 C147, 149 234,197 194 309  " stroke-width="1" fill="none"></path>
                       <path id="p-tewelve" d="M146 139 C147, 149 86,188 141 181 " stroke-width="1" fill="none"></path>


                       <use className="bg" href="#p_one"></use>
                       <use className="move" href="#p_one"></use>

                       <use className="bg" href="#p-two"></use>
                       <use className="move" href="#p-two"></use>

                       <use className="bg" href="#p-three"></use>
                       <use className="move" href="#p-three"></use>

                       <use className="bg" href="#p-four"></use>
                       <use className="move" href="#p-four"></use>

                       <use className="bg" href="#p-five"></use>
                       <use className="move" href="#p-five"></use>

                       <use className="bg" href="#p-six"></use>
                       <use className="move" href="#p-six"></use>
                       <use className="bg" href="#p-seven"></use>
                       <use className="move" href="#p-seven"></use>


                       <use className="bg" href="#p-eight"></use>
                       <use className="move" href="#p-eight"></use>

                       <use className="bg" href="#p-nine"></use>
                       <use className="move" href="#p-nine"></use>

                       <use className="bg" href="#p-ten"></use>
                       <use className="move" href="#p-ten"></use>
                       <use className="bg" href="#p-eleven"></use>
                       <use className="move" href="#p-eleven"></use>
                       <use className="bg" href="#p-tewelve"></use>
                       <use className="move" href="#p-tewelve"></use>

                   </svg>


               </div>
           </div>

           <div className="bg-white pb-5 p-md-5 p-2 shadow1 block">
               <h5 className={'display-5 p-3 pt-4 text-start'}>
                   {t('nav.contacts')}
               </h5>
               <p className={'p-3'}>
                   {t('contacts.text')}
               </p>
               <div className="contacts bg-white text-dark p-3">
                   <a onClick={()=>gaEventTracker('phone-kyivstar')} href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'text-secondary'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneKyivstar}</a> <br/>
                   {/*<a onClick={()=>gaEventTracker('phone-lifecell')} href={'tel.:' + BASIC_INFORMATION.phoneLifecell} className={'text-secondary'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneLifecell}</a> <br/>*/}
                   <br/>       <a onClick={()=>gaEventTracker('social-telegram')} href={BASIC_INFORMATION.socialTelegram}>
               </a>
                   <a onClick={()=>gaEventTracker('email')} href={
                       'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Contacts'
                   } className={'text-secondary'}> <FaIcons.FaEnvelope /> {BASIC_INFORMATION.email}</a> <br/>
                   <br/>
                   <a className={'text-secondary'}>  <FaIcons.FaMapMarker /> Ukraine, 10001 </a> <br/>
                   {/*<a className={'text-secondary'}>{t('contacts.address.street')}</a> <br/> <br/>*/}

               </div>

               <br/>

               <h4 className={'p-3'}>{t('messenger.title')}</h4>
               <div className="d-flex flex-wrap p-3 text-start">
                   <div className="col-md-3 col-6 p-md-0 p-2">

                       <a href={BASIC_INFORMATION.socialTelegram} target={'_blank'}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                fill="currentColor" className="bi bi-telegram text-info" viewBox="0 0 16 16">
                               <path
                                   d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                           </svg>
                           <small className="text-info m-1">
                               Telegram
                           </small>
                       </a>

                   </div>

                   <div className="col-md-3 col-6 p-md-0 p-2">

                       <a href={BASIC_INFORMATION.socialWhatsapp} target={'_blank'}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-whatsapp text-success" viewBox="0 0 16 16">
                               <path
                                   d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                           </svg>
                           <small className="text-success m-1">
                               WhatsApp
                           </small>
                       </a>

                   </div>


                   <div className="col-md-3 col-6 p-md-0 p-2">

                       <a href={BASIC_INFORMATION.socialViber} target={'_blank'}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-chat-dots-fill" style={{ color: '#7360F2'}} viewBox="0 0 16 16">
                               <path
                                   d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                           </svg>
                           <small className="m-1" style={{color: '#7260F2'}}>
                               Viber
                           </small>
                       </a>

                   </div>

                   <div className="col-md-3 col-6 p-md-0 p-2">

                       <a href={BASIC_INFORMATION.socialSignal} target={'_blank'}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-signal" viewBox="0 0 16 16">
                               <path
                                   d="m6.08.234.179.727a7.264 7.264 0 0 0-2.01.832l-.383-.643A7.9 7.9 0 0 1 6.079.234zm3.84 0L9.742.96a7.265 7.265 0 0 1 2.01.832l.388-.643A7.957 7.957 0 0 0 9.92.234zm-8.77 3.63a7.944 7.944 0 0 0-.916 2.215l.727.18a7.264 7.264 0 0 1 .832-2.01l-.643-.386zM.75 8a7.3 7.3 0 0 1 .081-1.086L.091 6.8a8 8 0 0 0 0 2.398l.74-.112A7.262 7.262 0 0 1 .75 8m11.384 6.848-.384-.64a7.23 7.23 0 0 1-2.007.831l.18.728a7.965 7.965 0 0 0 2.211-.919zM15.251 8c0 .364-.028.727-.082 1.086l.74.112a7.966 7.966 0 0 0 0-2.398l-.74.114c.054.36.082.722.082 1.086m.516 1.918-.728-.18a7.252 7.252 0 0 1-.832 2.012l.643.387a7.933 7.933 0 0 0 .917-2.219zm-6.68 5.25c-.72.11-1.453.11-2.173 0l-.112.742a7.99 7.99 0 0 0 2.396 0l-.112-.741zm4.75-2.868a7.229 7.229 0 0 1-1.537 1.534l.446.605a8.07 8.07 0 0 0 1.695-1.689l-.604-.45zM12.3 2.163c.587.432 1.105.95 1.537 1.537l.604-.45a8.06 8.06 0 0 0-1.69-1.691l-.45.604zM2.163 3.7A7.242 7.242 0 0 1 3.7 2.163l-.45-.604a8.06 8.06 0 0 0-1.691 1.69l.604.45zm12.688.163-.644.387c.377.623.658 1.3.832 2.007l.728-.18a7.931 7.931 0 0 0-.916-2.214M6.913.831a7.254 7.254 0 0 1 2.172 0l.112-.74a7.985 7.985 0 0 0-2.396 0l.112.74zM2.547 14.64 1 15l.36-1.549-.729-.17-.361 1.548a.75.75 0 0 0 .9.902l1.548-.357-.17-.734zM.786 12.612l.732.168.25-1.073A7.187 7.187 0 0 1 .96 9.74l-.727.18a8 8 0 0 0 .736 1.902l-.184.79zm3.5 1.623-1.073.25.17.731.79-.184c.6.327 1.239.574 1.902.737l.18-.728a7.197 7.197 0 0 1-1.962-.811l-.007.005zM8 1.5a6.502 6.502 0 0 0-6.498 6.502 6.516 6.516 0 0 0 .998 3.455l-.625 2.668L4.54 13.5a6.502 6.502 0 0 0 6.93-11A6.516 6.516 0 0 0 8 1.5"/>
                           </svg>
                           <small className="text-primary m-1">
                               Signal
                           </small>
                       </a>

                   </div>

               </div>

               <br/><br/>

               <div className="col-md-4 col-12 bg-custom p-3 text-center d-block d-md-none">
                   <a onClick={()=>gaEventTracker('redirect-single-to-offer')} href="/offer" className="text-white">{t('lets.talk')}</a>
               </div>

               <a onClick={()=>gaEventTracker('redirect-single-to-offer')} href="/offer" className="btn-custom-black m-3 d-none d-md-inline">{t('lets.talk')}</a>

           </div>

       </div>
    </div>);
}

export default ContactsPage;
