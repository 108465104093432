
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import PartnerCard from "../card/partner_card";
import PartnersApi from "../../service/api/partners_api";
import {MotionAnimate} from "react-motion-animate";

function AppSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <MotionAnimate
            animation='fadeInUp'
            reset={true}
            distance={200}
            delay={1}
            speed={1}>
        <div className="rounded-5  shadow-lg bg-white p-5 mt-5 pt-5 mb-5 pb-5">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <h5 className={'title-section mt- text-secondary'}>
                        Get app!
                    </h5>
                    <br/>
                    <p className={'text-secondary lead'}>
                        Coming soon..
                    </p>
                    <br/>
                    <a href="/#link-download-app" className={'btn-custom-black border-0 p-3'}>
                        {t('download')}
                    </a>
                    <a href="/mobile-application" className={'btn-custom-black1 text-dark border-0 p-3'}>
                        {t('Learn more')}
                    </a>
                </div>
                <div className="col-md-6">
                    <img src="https://i.pinimg.com/originals/f3/61/53/f3615368cf0f682093efd99ca9523393.png" alt="iphone" className={'img-fluid'}/>
                </div>
            </div>

        </div>
        </MotionAnimate>
    </div>);
}

export default AppSection;