import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import {useParams} from "react-router-dom";
import axios from "axios";
import {BACK_END_URL} from "../service/api/config";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function PageTracking(props) {

    const {t, i18n} = useTranslation()

    let {slug} = useParams();

    const [auth, setAuth] = useState(false);
    const [accessCode, setAccessCode] = useState();

    const [item, setItem] = useState();
    const [load, setLoader] = useState(false);

    const _submit = (e) => {

        e.preventDefault();

        try{
            setLoader(true);
            axios.post('http://127.0.0.1:8000/api/front-end/project/', {
                'access_code': accessCode
            }).then(function (response) {

                console.log(response.data);

            }).catch(error => {
                console.log(error);
            })
            setLoader(false);
        }catch (e){
            console.log(e)
        }
    }

    useEffect(() => {


    }, []);

    // for mobile dev get for growthup app iphone img

    if (load) {
        return <div>Loading...</div>
    }

    const logout = () => {
        setAuth(false)
    }

    const changeAccessCodeInput = (e) => {
        setAccessCode(e.target.value)
    }

    return (<div className={'h-100 pt-5'}>

        {
            auth ?
                <div className="container text-dark text-start pt-5 mt-5">



                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <h1 className={'title-section'}>
                                name project
                            </h1>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="text-end pb-2">
                                <button onClick={logout} className={'btn text-secondary border-0'}>(client@mail.com) Log out</button>
                            </div>

                            <div className="border p-2 rounded-5">
                                <div className="progress">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width: 150}}
                                             aria-valuenow="5" aria-valuemin="0" aria-valuemax="10">25%
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <div className="d-flex justify-content-evenly align-items-center">

                                <div className={'text-start col-md-4 col-6'}>
                                    <small className="text-secondary">deadline:</small>
                                    <span>12.12.2023</span>
                                </div>

                                <div  className={'text-start col-md-4 col-6'}>
                                    <small className="text-secondary">service:</small>
                                    <span>Mobile App</span>
                                </div>

                                <div  className={'text-start col-md-4 col-6'}>
                                    <small className="text-secondary">links:</small>
                                    <a href="">
                                        demo
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div  className={'text-end'}>
                                <small className="text-secondary">feedback:</small>
                                <a href="">
                                    leave
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5 vh-1001 mt-5">

                        <div className="col-md-8 m-auto">

                            <div className="rounded-5 bg-dark shadow p-5">
                                <h2 className={'text-secondary'}>
                                    History of development
                                </h2>
                                <p className={'text-white'}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci amet, aperiam consequatur doloremque ducimus eligendi enim eveniet fugiat harum illum labore, non omnis praesentium quae, quo rerum tempore vero voluptates.
                                </p>
                            </div>

                        </div>

                    </div>

                </div>
                :


                <div className="container text-dark text-start pt-5 mt-5">

                    <h1 className={'title-section'}>
                        Track process <br/>development you project
                    </h1>

                    <div className="row">

                        <div className="col-md-6">

                            <h3 className={'p-1'}>
                                Input your project access code for view history development.
                            </h3>

                            <div className="d-flex flex-wrap">

                                {/*3 спроби і блокувати вхід*/}

                                <div className="pt-5 pb-5 d-flex">

                                    <form onSubmit={_submit} method={'POST'} className={'shadow-lg bg-dark'}>
                                        <input type="text" className={'btn-custom-black border-0'}
                                               placeholder={'Project access code...'} value={accessCode} onChange={changeAccessCodeInput} required/>
                                        <button type={'submit'} className="btn-custom-black border-0 p-0"> <img src="/assets/icon/arrow.png"
                                                                                                                alt="icon-arrow"
                                                                                                                className={'arrow'}/></button>
                                    </form>

                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">

                            <img
                                src="https://cdn.dribbble.com/users/1523313/screenshots/13418499/timemanagement.gif"
                                alt="iphone" className={'img-fluid'}/>

                        </div>

                    </div>

                </div>
        }
    </div>);
}

export default PageTracking;
