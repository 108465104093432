import React, {Suspense, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import {useParams} from "react-router-dom";
import axios from "axios";
import {BACK_END_URL} from "../service/api/config";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

const PortfolioByServiceSection = React.lazy(() => import("../service/api/projects_api"));

function PageDetailService(props) {

    const {t, i18n} = useTranslation()

    let {slug} = useParams();

    const [item, setItem] = useState();
    const [load, setLoader] = useState(false);

    useEffect(() => {

        setLoader(true);
        axios.get('https://office.lukyanchuk.com/api/front-end/services?slug=' + slug).then(function (response) {

            setItem(response.data.data);
            console.log(response.data.data);

        }).catch(error => {
            console.log(error);
        })
        setLoader(false);
    }, []);

    // for mobile dev get for growthup app iphone img

    if (load) {
        return <div>Loading...</div>
    }

    return (<div className={'h-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'text-uppercase'}>
                {slug}
            </h1>

            <div className="row">

                <div className="col-md-6">

                    <h1 className={'title-section'}>
                        {i18n.language === 'en' ? item?.name?.en : item?.name?.ua}
                    </h1>

                    <p className="lead mt-5">
                        {i18n.language === 'en' ? item?.note?.en : item?.note?.ua}
                    </p>


                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita nobis qui reiciendis sit unde? Atque consectetur consequatur doloremque ea labore minus nisi nobis perferendis, placeat possimus provident quae veniam, voluptas.
                    </p>

                    <h3>
                        Let's cooperation together
                    </h3>


                    <div className="pt-5 pb-5">

                        <a href="/contact" className="btn-custom-black">Обговоримо ваш проект?</a>

                    </div>


                </div>

                <div className="col-md-6 col-12 m-auto">
                    {
                        slug === 'website' ?
                            <img src="https://cdn.osxdaily.com/wp-content/uploads/2014/09/macbook-pro-black-screen.jpg"
                                 alt="macbook" className={'img-fluid'}/>

                            : <img
                                src="https://img.freepik.com/premium-vector/mobile-smartphone-phone-mockup-isolated-white-background-with-blank-screen_88272-4578.jpg"
                                alt="iphone" className={'img-fluid'}/>
                    }
                </div>

            </div>

            <div className={'bg-white1 d-flex justify-content-between align-items-center p-1'}>
                <div className={'col-md-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" alt="Laravel" className={'img-fluid p-5'}/>
                    {/*<br/>*/}
                    {/*Laravel*/}
                </div>

                <div className={'col-md-2 text-center'}>
                    <img src="https://plugins.jetbrains.com/files/9212/376589/icon/pluginIcon.png" alt="Flutter" className={'img-fluid p-3'}/>
                    {/*<br/>*/}
                    {/*Flutter*/}
                </div>

                <div className={'col-md-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" alt="react.js" className={'img-fluid p-5'}/>
                    {/*<br/>*/}
                    {/*React.JS*/}
                </div>

                <div className={'col-md-2 text-center'}>
                    <img src="https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png" alt="next.js" className={'img-fluid p-2'}/>
                    {/*<br/>*/}
                    {/*React.JS*/}
                </div>

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png" alt="Bootstrap" className={'img-fluid p-5'}/>*/}
                {/*    /!*<br/>*!/*/}
                {/*    /!*Bootstrap*!/*/}
                {/*</div>*/}

                <div className={'col-md-2 text-center'}>
                    <img src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/23-swift-512.png" alt="Bootstrap" className={'img-fluid p-5'}/>
                    {/*<br/>*/}
                    {/*Bootstrap*/}
                </div>

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/MySQL-Logo.wine.png" alt="MySQL" className={'img-fluid p-5'}/>*/}
                {/*    /!*<br/>*!/*/}
                {/*    /!*MySQL*!/*/}
                {/*</div>*/}

                <div className={'col-md-2 text-center'}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>
                </div>

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://www.mamp.info/images/icons/mamp-pro.png" alt="Git" className={'img-fluid p-5'}/>*/}
                {/*</div>*/}

                {/*<div className={'col-md-2 text-center'}>*/}
                {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1200px-Visual_Studio_Code_1.35_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>*/}
                {/*</div>*/}
            </div>


            <div id={'projects'} className="p-5">

                <small className="text-secondary">
                    Get acquainted with the project from Portfolio
                </small>
                <h2>
                    {i18n.language === 'en' ? item?.name?.en : item?.name?.ua} {slug} Projects
                </h2>

                {/*https://office.lukyanchuk.com/api/front-end/projects?service=1*/}

                <Suspense fallback={<div>Loading...</div>}>
                    <PortfolioByServiceSection service={4} limit={3} />

                    <div className="pt-5 pb-5 text-center">

                        <a href={"/portfolio/service/" + item?.id} className="btn-custom-black">View More</a>

                    </div>
                </Suspense>

            </div>

            {/*<div id={'feedback'} className={'container p-5 text-start'}>*/}
            {/*    <h2>Feedbacks</h2>*/}
            {/*    <small className="text-secondary">*/}
            {/*        <i>*/}
            {/*            При додаванні коментарі перевіряти за email чи був проект, і тоді додаватти коментар*/}
            {/*        </i>*/}
            {/*    </small>*/}


            {/*    <div className="comment mt-5 row">*/}

            {/*        <div className="col-md-4 col-12">*/}
            {/*            <img src="https://ionicframework.com/docs/img/demos/avatar.svg" alt="avatar" className={'img-fluid w-25 rounded-circle'}/>*/}
            {/*            <span className={'text-start'}>*/}
            {/*                Username*/}
            {/*            </span>*/}
            {/*        </div>*/}

            {/*        <div className="col-md-8 col-12">*/}

            {/*            <div className="d-flex justify-content-between align-items-center mb-2">*/}

            {/*                <small className="col-md-6 text-secondary d-flex">*/}
            {/*                    <div className="d-flex w-50">*/}
            {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
            {/*                             className="bi bi-star-fill" viewBox="0 0 16 16">*/}
            {/*                            <path*/}
            {/*                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>*/}
            {/*                        </svg>*/}
            {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
            {/*                             className="bi bi-star-fill" viewBox="0 0 16 16">*/}
            {/*                            <path*/}
            {/*                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>*/}
            {/*                        </svg>*/}
            {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
            {/*                             className="bi bi-star-fill" viewBox="0 0 16 16">*/}
            {/*                            <path*/}
            {/*                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>*/}
            {/*                        </svg>*/}
            {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
            {/*                             className="bi bi-star-fill" viewBox="0 0 16 16">*/}
            {/*                            <path*/}
            {/*                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>*/}
            {/*                        </svg>*/}
            {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
            {/*                             className="bi bi-star" viewBox="0 0 16 16">*/}
            {/*                            <path*/}
            {/*                                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>*/}
            {/*                        </svg>*/}
            {/*                    </div>*/}
            {/*                    <div className="w-50">*/}
            {/*                    </div>*/}
            {/*                </small>*/}

            {/*                <small className="col-md-6 text-secondary text-end">*/}
            {/*                    12.12.12*/}
            {/*                </small>*/}
            {/*            </div>*/}

            {/*            <p className="lead text-start p-3">*/}
            {/*                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur at blanditiis commodi*/}
            {/*                deleniti dolor earum ex ipsam maxime modi nemo obcaecati odio quaerat, saepe sapiente sed sunt*/}
            {/*                totam vel, voluptates!*/}
            {/*            </p>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*</div>*/}

        </div>
    </div>);
}

export default PageDetailService;
