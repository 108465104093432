
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import PartnerCard from "../card/partner_card";
import PartnersApi from "../../service/api/partners_api";

function PartnersSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="bg-white p-2 mt-5 pt-5 mb-5 pb-5">
            <h5 className={'title-section mt-5 text-secondary'}>
                {t('partners')}
            </h5>
            <p className={'lead text-secondary mt-2 mb-5 pb-5'}>
                {t('partners.text')}
            </p>
            <div className="border border-dark m-2 p-4 text-center"><a
                href="https://billing.hostpro.ua/aff.php?aff=7643"><img
                src="https://billing.hostpro.ua/partners/uk/2021/vps/728x90.png" alt="Hostpro" rel="nofollow"
                className="img-fluid"/></a>
            </div>

            {/*<PartnersApi />*/}
        </div>
    </div>);
}

export default PartnersSection;