
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import PartnerCard from "../card/partner_card";
import PartnersApi from "../../service/api/partners_api";
import {MouseParallax} from "react-just-parallax";
import {MotionAnimate} from "react-motion-animate";
import './book.css';

function BooksSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="row justify-content-center align-items-center bg-white p-2 mt-5 mb-5 pb-5">
            <div className="col-md-6 col-12">
                <h5 className={'title-section mt-md-2 mt-2'}>
                    {t('books')}
                </h5>
                <p className={'lead text-secondary mt-2 mb-5 pb-2'}>
                  2024  {/*{t('clients.text')}*/}
                </p>

                <a href="https://books.lukyanchuk.com/book/one-percent-changes-do-it-now/" target={'_blank'} className={'text-dark'}>
                <h2 className={'display-5'}>
                    1% Змін.
                </h2>
                <small className="text-secondary lead">
                    Час зроби це зараз!
                </small>
                </a>

                <br/><br/><br/><br/>

                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link" target={'_blank'} className={'btn-custom-black border-0 p-3'}>{t('nav.buy')}</a>
                <a href="https://books.lukyanchuk.com/books" className={'text-secondary'}> {t('nav.view-all')}</a>
            </div>


            <div className="col-md-6 col-12 text-center">
                {/*<MouseParallax>*/}

                <MotionAnimate
                    animation='fadeInUp'
                    reset={true}
                    distance={100}
                    delay={1}
                    speed={1}>
                        <a
                            className="book-container col-md-6 col-12"
                            href="https://books.lukyanchuk.com/book/one-percent-changes-do-it-now/"
                            rel="noreferrer noopener"
                        >
                            <div className="book">
                                <img
                                    className={'img-fluid'}
                                    alt="book"
                                    src={'https://books.lukyanchuk.com/image/cover/book_cover.png'}
                                />
                            </div>
                        </a>

                </MotionAnimate>
                {/*</MouseParallax>*/}

            </div>

        </div>
    </div>);
}

export default BooksSection;