import React from "react";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
    {
        title: "Home",
        path: "/",
        icon: <AiIcons.AiFillHome />,
        cName: "nav-text"
    },
    // {
    //     title: "Cooperation",
    //     path: "/cooperation",
    //     icon: <FaIcons.FaRegHeart />,
    //     cName: "nav-text"
    // },

    {
        title: "Services",
        path: "#services",
        icon: <FaIcons.FaCode />,
        // icon: <FaIcons.FaRegStickyNote />,
        cName: "nav-text"
    },

    {
        title: "Portfolio",
        path: "/portfolio",
        icon: <FaIcons.FaRegBookmark />,
        cName: "nav-text"
    },

    // {
    //     title: "Projects",
    //     path: "/projects",
    //     icon: <FaIcons.FaConnectdevelop />,
    //     cName: "nav-text"
    // },
    {
        title: "About me",
        path: "/about-me",
        icon: <FaIcons.FaUserAlt />,
        cName: "nav-text"
    },
    {
        title: "Contacts",
        path: "/contacts",
        icon: <FaIcons.FaEnvelope />,
        cName: "nav-text"
    },

    // {
    //     title: "Resume",
    //     path: "https://resume.lukyanchuk.com",
    //     icon: <FaIcons.FaFilePdf />,
    //     cName: "nav-text"
    // },
    // {
    //     title: "About",
    //     path: "https://yaroslav.lukyanchuk.com/about-me",
    //     icon: <FaIcons.FaHistory />,
    //     cName: "nav-text"
    // },
    // {
    //     title: "Apps",
    //     path: "https://apps.lukyanchuk.com",
    //     icon: <FaIcons.FaAppStore />,
    //     cName: "nav-text"
    // },
    // {
    //     title: "Books",
    //     path: "https://books.lukyanchuk.com",
    //     icon: <FaIcons.FaBook />,
    //     cName: "nav-text"
    // }
];
