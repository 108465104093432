import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next"
import PortfolioCard from "../components/card/portfolio_card";
import {ProjectApi} from "../service/api/projects_api";
import axios from "axios";
import {BACK_END_URL} from "../service/api/config";
import {useParams} from "react-router-dom";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function ProjectsPage(props) {

    const { t, i18n } = useTranslation()

    let {name} = useParams();

    const gaEventTracker = useAnalyticsEventTracker('Projects');

    // const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [project, setProject] = useState([]);
    const [load, setLoad] = useState(false);
    const [isActive, setActive] = useState(0);

    const makeQuery = (id) => {

        console.log('before ' + isActive);
        getData('&category=' + id);
        setActive(id);
        console.log('after ' + id);
        gaEventTracker('category_id: '+id)
    };

    const getData = (query) => {
        setLoad(true);
        // BACK_END_URL +
        axios.get(BACK_END_URL + 'projects?sort=DESC' + query).then(function (response){
            console.log(response.data);

            const getData = response.data;
            setProject(getData.data);

        }).catch(error => {
            console.log(error);
        })
        setLoad(false);
    }


    const getCategories = (query) => {
            setLoad(true);
            axios.get(BACK_END_URL + 'categories' + query).then(function (response){
                console.log(response.data);

                const getData = response.data;
                setCategories(getData.data);
                window.localStorage.setItem("categories", JSON.stringify(getData.data));

            }).catch(error => {
                console.log(error);
            })
            setLoad(false);
    }

    useEffect(()=>{

        // if(localStorage.getItem('categories') !== undefined){
        //     setCategories(JSON.stringify(localStorage.getItem('categories')));
        // }

        if(categories === [] || categories === null){
            getCategories('');
        }

        console.log(props.type)
        console.log(name)

        if(props.type === 'tag'){
            setActive(-1)
            getData('&tag='+name);
        } else if(props.type === 'service'){
            setActive(name)
            makeQuery(name)
        }
        else{
            getData('');
        }
    },[])

    const lang = i18n.language;

    return (<div className={'pt-5 mt-5'}>

        <div className="container m-auto portfolio-header d-flex flex-md-row flex-column-reverse align-items-center">
            {/*<div className="text-start col-md-6 pb-5">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*        <img src="/assets/icon/arrow-black.png" alt="icon-arrow" className={'w-25'}/>*/}
            {/*        <a href="/offer" className="btn-custom-black">{t('lets.talk')}</a>*/}
            {/*    </div>*/}
            {/*    <div className="d-flex flex-wrap mt-5">*/}
            {/*        <div className="p-1">*/}
            {/*            <button  key={0} className={isActive === 0 ? 'border-0 btn-custom-black-active': 'border-0 btn-custom-black'}*/}
            {/*                     onClick={(e) => makeQuery(0)} value={0}>*/}
            {/*                {t('all')}*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*        {*/}
            {/*            (categories === null || categories === []) ? <div className={'text-secondary'}>No Option</div> :*/}
            {/*            Object.values(categories).map((item, i) =>  {*/}
            {/*            return (*/}
            {/*                <div className="p-1">*/}
            {/*                    <button  key={i} className={isActive === item.id ? 'border-0 btn-custom-black-active': 'border-0 btn-custom-black'}*/}
            {/*                            onClick={(e) => makeQuery(item.id)} value={item.id}>*/}
            {/*                        {lang === 'en' ? item.name?.en : item.name?.ua}*/}
            {/*                       1*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            );*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="text-start col-md-12">
                <h5 className={'title-section p-0'}>
                    {t('nav.portfolio')}
                </h5>
                <p className={'lead mt-2p-0'}>
                    {t('portfolio.text')}
                </p>
            </div>
        </div>
        <div className="container-fluid d-flex flex-wrap pb-5 mb-5 p-md-5 p-0">

            <div className="container- d-flex flex-wrap">
                {
                    load ? <div className={'text-secondary'}>Loading</div> :
                        project == null || project == [] ? <div className={'text-secondary'}>No Records</div> :
                            project.map((e, index) => <PortfolioCard key={index} data={e} />)
                }
            </div>
        </div>
    </div>);
}

export default ProjectsPage;
