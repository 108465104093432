
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Accordion} from "react-bootstrap";
import axios from "axios";
import {BACK_END_URL} from "../../service/api/config";
import useAnalyticsEventTracker from "../../service/g-analytics/useAnalyticsEventTracker";

function ServiceSection(props) {

    const { t, i18n } = useTranslation()

    const gaEventTracker = useAnalyticsEventTracker('service');

    const [service, setService] = useState([])

    const getData = () => {
        axios.get(BACK_END_URL + 'services').then(function (response){

            const getService = response.data;
            setService(getService.data);
            window.localStorage.setItem("services", JSON.stringify(getService.data));
            console.log(getService.data);
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        // setService(JSON.parse(localStorage.getItem('services')))
        getData();
    }, [])

    const services = Object.values(service).map((item, i) =>  {
        return (
            <Accordion.Item eventKey={i}>
                <Accordion.Header>
                    0{i+1} {item.name[i18n.language === 'en' ? 'en' : 'ua']}
                </Accordion.Header>
                <Accordion.Body>
                    {
                        item.note[i18n.language === 'en' ? 'en' : 'ua']
                    }
                    <br/><br/>

                    <a href={"/cooperation"} className={'link-know-more1 text-white'}>
                        <img src="/assets/icon/arrow.png" alt="icon-arrow" className={'arrow'}/>
                    {/*    {t('nav.Join')}*/}
                    {/*</a>*/}

                    {/*<a href={"/services/" + item.slug} className={'link-know-more1 text-white p-5'}>*/}
                        {/*<img src="/assets/icon/arrow.png" alt="icon-arrow" className={'arrow'}/>*/}
                        {/*{t('nav.know.more')}*/}
                        {t('nav.cooperation')}
                    </a>

                    {/*<label className={'text-white'}>{t('nav.view')}</label> <a onClick={()=>gaEventTracker('service ' + item.name['en'])} href={'/portfolio/service/'+item.id} className={'text-warning'}>{t('example.text')}</a>*/}
                </Accordion.Body>
            </Accordion.Item>
        );
    });

    return (<div id={'services'} className={'bg-custom pt-1 pb-5'}>
        {/*<hr/>*/}
        {/*<ServiceApi/>*/}

        <h5 className={'title-section mt-5 text-white pt-5'}>
            {t('nav.services')}
        </h5>
        <span className={'text-white display-6 m-0'}>& {t('nav.offer')}</span>


        <div className="text-white row p-2">
            <div className="left col-md-6 col-12">
                <p className={'lead mt-5 mb-5 pb-5 text-white'}>
                    {t('services.and.offer.text')}
                </p>

                <div className="text-start d-flex justify-content-start">
                    <div className="col-md-4">
                        <div className="d-none- d-md-block">
                            <div className="btn-circle">
                                <a onClick={()=>gaEventTracker('offer')} href="/cooperation" className={'text-center font-monospace'}>
                                    {t('service.button.talk')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>
            </div>
            <div className="right col-md-6 col-12 p-2">

                {/*<code>*/}
                {/*    only: web, app + btn more service (open page services)*/}
                {/*</code>*/}

                <Accordion defaultActiveKey={['1']} flush>

                    {services}

                </Accordion>

            </div>
        </div>

        {/*<div id={'stack'} className={'bg-white1 d-flex justify-content-between align-items-center p-1'}>*/}
        {/*    <div className={'col-md-2 text-center'}>*/}
        {/*        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" alt="Laravel" className={'img-fluid p-5'}/>*/}
        {/*        /!*<br/>*!/*/}
        {/*        /!*Laravel*!/*/}
        {/*    </div>*/}

        {/*    <div className={'col-md-2 text-center'}>*/}
        {/*        <img src="https://plugins.jetbrains.com/files/9212/376589/icon/pluginIcon.png" alt="Flutter" className={'img-fluid p-3'}/>*/}
        {/*        /!*<br/>*!/*/}
        {/*        /!*Flutter*!/*/}
        {/*    </div>*/}

        {/*    <div className={'col-md-2 text-center'}>*/}
        {/*        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" alt="react.js" className={'img-fluid p-5'}/>*/}
        {/*        /!*<br/>*!/*/}
        {/*        /!*React.JS*!/*/}
        {/*    </div>*/}

        {/*    /!*<div className={'col-md-2 text-center'}>*!/*/}
        {/*    /!*    <img src="https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png" alt="next.js" className={'img-fluid p-2'}/>*!/*/}
        {/*    /!*    /!*<br/>*!/*!/*/}
        {/*    /!*    /!*React.JS*!/*!/*/}
        {/*    /!*</div>*!/*/}

        {/*    <div className={'col-md-2 text-center'}>*/}
        {/*        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png" alt="Bootstrap" className={'img-fluid p-5'}/>*/}
        {/*        /!*<br/>*!/*/}
        {/*        /!*Bootstrap*!/*/}
        {/*    </div>*/}

        {/*    /!*<div className={'col-md-2 text-center'}>*!/*/}
        {/*    /!*    <img src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/23-swift-512.png" alt="Bootstrap" className={'img-fluid p-5'}/>*!/*/}
        {/*    /!*    /!*<br/>*!/*!/*/}
        {/*    /!*    /!*Bootstrap*!/*!/*/}
        {/*    /!*</div>*!/*/}

        {/*    <div className={'col-md-2 text-center'}>*/}
        {/*        <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/MySQL-Logo.wine.png" alt="MySQL" className={'img-fluid p-5'}/>*/}
        {/*        /!*<br/>*!/*/}
        {/*        /!*MySQL*!/*/}
        {/*    </div>*/}

        {/*    <div className={'col-md-2 text-center'}>*/}
        {/*        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>*/}
        {/*    </div>*/}

        {/*    /!*<div className={'col-md-2 text-center'}>*!/*/}
        {/*    /!*    <img src="https://www.mamp.info/images/icons/mamp-pro.png" alt="Git" className={'img-fluid p-5'}/>*!/*/}
        {/*    /!*</div>*!/*/}

        {/*    /!*<div className={'col-md-2 text-center'}>*!/*/}
        {/*    /!*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1200px-Visual_Studio_Code_1.35_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*</div>*/}

    </div>);
}

export default ServiceSection;