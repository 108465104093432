

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../service/information/basic";
import * as FaIcons from "react-icons/fa";

function PageAboutMe(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'title-section'}>{t('nav.about me')}</h1>

            <p className="lead">
                {t('nav.about me.text')}
            </p>

            <div className="row pb-5">
                <div className="col-md-8 row m-auto">
                    <img src="/assets/image/me.jpg" alt="me" className={'img-fluid p-0 m-md-auto mb-5 shadow'}/>
                    <small className="text-secondary">
                        <i>
                            Ярослав Лук'янчук, 2017
                        </i>
                    </small>
                </div>
                <div className="col-md-4 border p-5">
                    <h2>
                        {t('hello.2')}
                    </h2>
                    <br/>
                    <p className={'text-justify'}>
                        {t('about me.text')}
                        {/*<br/><br/>*/}
                        {/*<a href="https://yaroslav.lukyanchuk.com" className={'text-secondary'}>*/}
                        {/*    more biography info*/}
                        {/*</a>*/}
                        {/*<a href="https://links.lukyanchuk.com" className={'text-secondary'}>*/}
                        {/*    https://links.lukyanchuk.com*/}
                        {/*</a>*/}
                    </p>
                    <div className="d-flex justify-content-between align-items-center mt-5">
                        <a href="https://resume.lukyanchuk.com" className={'color-main'}>
                            <b>{t('nav.resume')}</b>
                        </a>

                        <a href="/offer" className="btn-custom-black">{t('lets.talk')}</a>
                    </div>
                </div>
            </div>

            {/*<div className="border">*/}
            {/*    add resume, bigraphy, books, apps,*/}
            {/*</div>*/}

            <h5 className={'text-dark p-3'}>
                {t('nav.follow-us')}
            </h5>
                    <div className="about-me links- text-start pt-2 d-flex flex-wrap mb-5 pt-5 pb-5">
                        <div className="p-3 col-md-4 col-6">
                            <a href={BASIC_INFORMATION.socialInstagram} className={'text-secondary'}> <span>Instagram</span></a>
                        </div>
                        <div className="p-3 col-md-4  col-6">
                            <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}>  <span>Telegram</span></a>
                        </div>
                        <div className="p-3 col-md-4  col-6">
                            <a href={BASIC_INFORMATION.socialFacebook} className={'text-secondary'}>  <span>Facebook</span></a>
                        </div>
                        <div className="p-3 col-md-4  col-6">
                            <a href={BASIC_INFORMATION.socialGitHub} className={'text-secondary'}>  <span>GitHub</span></a>
                        </div>
                        <div className="p-3 col-md-4  col-6">
                            <a href={BASIC_INFORMATION.socialLinkedIn} className={'text-secondary'}> <span>LinkedIn</span></a>
                        </div>
                        <div className="p-3 col-md-4  col-6">
                            <a href={BASIC_INFORMATION.socialTwitter} className={'text-secondary'}> <span>Twitter</span></a>
                        </div>

                        <div className="p-3 col-md-12  col-12">
                            <p>
                                {t('nav.know.more')}
                            <a href="https://yaroslav.lukyanchuk.com/links" className={'text-secondary'}>
                                <img src="/assets/icon/arrow-black.png" alt="icon-arrow" className={'arrow'}/>  links
                            </a>
                            </p>
                        </div>

                    </div>


        </div>
    </div>);
}

export default PageAboutMe;
