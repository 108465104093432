import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import CooperationForm from "./cooperation_form";
import BASIC_INFORMATION from '../service/information/basic';
import * as FaIcons from "react-icons/fa";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function FormOffer(props) {

    const { t, i18n } = useTranslation()

    const gaEventTracker = useAnalyticsEventTracker('Offer');

    return (<div className={''}>
        <div className="row d-flex justify-content-between align-items-start p-md-2 p-1 shadow-lg pb-5">
            <div className="col-12 col-md-5 form-title p-3">
                <h5 className={'display-5 p-3 pt-4 text-start'}>
                    {t('form.title2')}
                    {/*{t('form.title')}*/}
                </h5>
                <div className="offer-contacts text-start w-md-50 w-100 pt-1 p-1">

                    <span className={'text-secondary'}>{t('nav.contacts')}</span>
                    <div className="links text-start pt-4 p-3">

                        <a onClick={()=>gaEventTracker('phone')} href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'text-white'}> <FaIcons.FaMobileAlt />
                            <span>{BASIC_INFORMATION.phoneKyivstar}</span>
                        </a>
                        <br/> <br/>
                        {/*<span>*/}
                        {/*    <a href={'tel.:' + BASIC_INFORMATION.phoneLifecell} className={'text-white'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneLifecell}</a>*/}
                        {/*</span> <br/> <br/>*/}
                        <a onClick={()=>gaEventTracker('email')} href={
                            'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Footer'
                        } className={'text-white'}><FaIcons.FaEnvelope /> <span> {BASIC_INFORMATION.email}</span></a> <br/>
                    </div>

                    {/*<span className={'text-secondary'}>{t('nav.follow-us')}</span>*/}
                    {/*<div className="links text-start pt-2 d-flex flex-wrap">*/}
                    {/*    <div className="p-3 col-md-4 col-6">*/}
                    {/*        <a onClick={()=>gaEventTracker('instagram')} href={BASIC_INFORMATION.socialInstagram} className={'text-white'}> <FaIcons.FaInstagram /> <span>Instagram</span></a>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-3 col-md-4  col-6">*/}
                    {/*        <a onClick={()=>gaEventTracker('telegram')} href={BASIC_INFORMATION.socialTelegram} className={'text-white'}> <FaIcons.FaTelegram /> <span>Telegram</span></a>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-3 col-md-4  col-6">*/}
                    {/*        <a onClick={()=>gaEventTracker('facebook')} href={BASIC_INFORMATION.socialFacebook} className={'text-white'}> <FaIcons.FaFacebook /> <span>Facebook</span></a>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-3 col-md-4  col-6">*/}
                    {/*        <a onClick={()=>gaEventTracker('github')} href={BASIC_INFORMATION.socialGitHub} className={'text-white'}> <FaIcons.FaGithub /> <span>GitHub</span></a>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-3 col-md-4  col-6">*/}
                    {/*        <a onClick={()=>gaEventTracker('linkedin')} href={BASIC_INFORMATION.socialLinkedIn} className={'text-white'}> <FaIcons.FaLinkedinIn /> <span>LinkedIn</span></a>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-3 col-md-4  col-6">*/}
                    {/*        <a onClick={()=>gaEventTracker('twitter')} href={BASIC_INFORMATION.socialTwitter} className={'text-white'}> <FaIcons.FaTwitter /> <span>Twitter</span></a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*<a onClick={()=>gaEventTracker('about-me')} href="/about-me" className={'color-main- text-white p-3'}>*/}
                {/*    <b>{t('nav.about me')}</b>*/}
                {/*</a>*/}
            </div>
            <div className="col-12 col-md-7 form-fields p-2">
                <CooperationForm lang={i18n.language} />
            </div>
        </div>
    </div>);
}

export default FormOffer;
