

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../service/information/basic";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

function PageInformation(props) {

    const { t, i18n } = useTranslation()

    const gaEventTracker = useAnalyticsEventTracker('Information');

    return (<div>

        <div className={'h-100 pt-5'}>

            <div className="container text-dark text-start pt-5 mt-5">

                <h1 className={'title-section'}> {t('nav.info')}</h1>

                <Section title={''} name={'information'} child={
                    <div className={'mt-5'}>

                        <div id="privacy-policy">
                            <h4>
                                {t('nav.privacy-policy')}
                            </h4>
                            <p className={'text-justify'}>
                                {t('privacy-policy.01')}
                            </p>
                            <p className={'text-justify'}>
                                {t('privacy-policy.02')}
                            </p>
                            <p className={'text-justify'}>
                                {t('privacy-policy.03')}
                            </p>
                            <p className={'text-justify'}>
                                {t('privacy-policy.04')}
                            </p>
                            <p className={'text-justify'}>
                                {t('privacy-policy.05')}
                            </p>
                        </div>

                        <div id="term-of-use">
                            <h4>
                                {t('nav.term-of-use')}
                            </h4>
                            <p className={'text-justify'}>
                                {t('term-of-use.01')}
                            </p>
                        </div>
                    </div>
                }/>

                <div className="pt-5 pb-5">

                    <a onClick={()=>gaEventTracker('back-home')} href="/" className="btn-custom-black">Go to Home</a>

                </div>
            </div>
        </div>

    </div>);
}

export default PageInformation;
