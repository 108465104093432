
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import PartnerCard from "../card/partner_card";
import PartnersApi from "../../service/api/partners_api";

function ClientsSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="bg-white p-2 mt-5 pt-5 mb-5 pb-5">
            <h5 className={'title-section mt-5 text-secondary'}>
                {t('clients')}
            </h5>
            {/*<p className={'lead text-secondary mt-2 mb-5 pb-5'}>*/}
            {/*    {t('clients.text')}*/}
            {/*</p>*/}


            <div className="p-1">

                <div id={'clients-section'} className="row">
                    <div className="col-md-3 col-6">
                        <div className="client-card p-md-5 p-1 text-center">
                            <a href={'http://akbkramnyca.com/'} target={'_blank'}>
                                <img src="https://office.lukyanchuk.com/storage/files/client/akb.png" alt="akbkramnyca" className={'img-fluid'}/>
                                <br/>
                                <small className={'text-secondary mt-2'}><i>
                                    АКБ Крамниця
                                </i></small>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="client-card p-md-5 p-1 text-center">
                            <a href={'https://likework.net.ua'} target={'_blank'}>
                                <img src="https://office.lukyanchuk.com/storage/files/client/likework.png" alt="likework" className={'img-fluid'}/>
                                <small className={'text-secondary mt-2'}><i>
                                    LikeWork HR Partners
                                </i></small>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="client-card p-5 text-center">
                            <a href={'https://ukrsocks.ua'} target={'_blank'}>
                                <img src="https://ukrsocks.ua/image/catalog/logo-2.png" alt="likework" className={'img-fluid'}/>
                                <small className={'text-secondary mt-2'}><i>
                                    Ukrsocks.ua
                                </i></small>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 col-6">
                        <div className="client-card p-md-5 p-1 text-center">
                            <a href={'/cooperation'}>
                                {/*https://cdn.theatlantic.com/thumbor/3qQFueH0SlALep_5bCldWoHbSIA=/0x0:7200x4050/960x540/media/img/mt/2023/02/Tyler_Comrie_The_Atlantic01/original.jpg*/}
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/800px-User-avatar.svg.png" alt="you" width={'75'} className={'img-fluid'}/>
                                <br/>
                                <small className={'text-secondary mt-2'}><i>
                                    Become a customer
                                </i></small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}

export default ClientsSection;