import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import ServiceSection from "../components/section/service_section";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";
import './services.css';

function ServicesPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className="vh-md-100 h-100 pt-5 mt-5 mb-5 pb-5 container d-flex flex-wrap align-items-center justify-content-center">

        <div className={'pt-5 text-center col-md-2 col-12'}>
            <h1 className={'display-4 lead'}>
                {t('nav.services')}
            </h1>
        </div>

        <div className="col-md-10 col-12 d-flex flex-wrap">

            <div className="col-md-4 col-12">
                <div className="bg-dark p-5 shadow-lg rounded-5 text-white m-2 align-items-center justify-content-center d-flex" style={{
                    height: '490px',
                    backgroundPosition: 'center',
                    backgroundImage: 'url("https://cdn.thewirecutter.com/wp-content/media/2023/09/iphone-2048px-0786.jpg")',
                    // backgroundImage: 'url("https://www.easternts.com/wp-content/uploads/2023/01/iphone-app.gif")',
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat, repeat",
                }}>
                    <h2>
                        <b>
                            App
                        </b>
                    </h2>
                </div>
            </div>

            <div className="col-md-8 col-12 d-flex flex-wrap">
                <div className="col-md-6 col-12">
                    <div className="p-5 shadow-lg1 rounded-5 text-white m-2 align-items-center justify-content-center d-flex" style={{
                        height: '240px',
                        backgroundSize: 'cover',
                        backgroundRepeat: "no-repeat, repeat",
                        backgroundImage: 'url("https://cdn.thewirecutter.com/wp-content/media/2022/08/macbook-2048px-9765.jpg")'
                    }}>
                        <h2>web</h2>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="bg-dark p-5 shadow-lg rounded-5 text-white m-2 align-items-center justify-content-center d-flex"
                         style={{
                             height: '240px',
                             backgroundSize: 'cover',
                             backgroundRepeat: "no-repeat, repeat",
                             backgroundImage: 'url(https://www.zilliondesigns.com/blog/wp-content/uploads/logo-trends.jpg)'
                         }}
                    >
                        <h2 className={'text-dark bg-white'}>design</h2>
                    </div>
                </div>
                <div className="col-md-9 col-12">
                    <div className="bg-dark p-5 shadow-lg1 rounded-5 text-white m-2 align-items-center justify-content-center d-flex"
                         style={{
                             height: '230px',
                             backgroundSize: 'cover',
                             backgroundRepeat: "no-repeat, repeat",
                             backgroundImage: 'url(https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Untitled-1_hzGCoTf.jpg)'
                         }}
                    >
                        <h2 className={'text-dark bg-white'}> support</h2>
                    </div>
                </div>
                <div className="col-md-3 col-12">
                    <div className="bg-dark p-5 shadow-lg1 rounded-5 text-white m-2 align-items-center justify-content-center d-flex" style={{
                        height: '230px',
                        backgroundSize: 'cover',
                        backgroundRepeat: "no-repeat, repeat",
                        backgroundImage: 'url(https://cdn-icons-png.flaticon.com/512/3430/3430793.png)'
                    }}>
                        <h2>
                    other
                        </h2>
                    </div>
                </div>
            </div>

        </div>

        {/*<ServiceSection />*/}
    </div>);
}

export default ServicesPage;
